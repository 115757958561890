import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Navbar from '../../components/Navbar/Navbar'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {toast} from "react-toastify";
import { getImageSize } from 'react-image-size';
import Cookies from 'universal-cookie'

const Gallery = () => {
    const [index, setIndex] = useState(-1);
    const [photos, setPhotos] = useState(null);
    const [highQualityPhotos, setHighQualityPhotos] = useState(null);

    const getPhotoList = async() => {
        const apiKey = localStorage.getItem('apiKey');
        const response = await fetch('https://hermann-hambrecht-wedding.de/api/images', { 
            method: 'GET',
            headers: {
                'x-api-key': apiKey
            }
        });

        const data = await response.json();

        if(data.statusCode == 200) {
            const body = JSON.parse(data.body);
            setPhotos(body.files);

            const highQualityList = body.files.map(o => {
                return { src: o.path, key: o.key, width: o.width, height: o.height };
            })

            setHighQualityPhotos(highQualityList);
        }
    };

    useEffect(() => {
        const cookie = new Cookies();
        const code = cookie.get('code');

        if(code == undefined || code == null) {
            window.location = '/login';
        }
        
        if(photos === undefined || photos === null)
            getPhotoList();
    });

    // specify upload params and url for your files
    const getUploadParams = async ({ file, meta: { name } }) => { 
        const url = URL.createObjectURL(file);
        const { width, height } = await getImageSize(url);

        const {uploadUrl, fileUrl } = await getPresignedUrl(file, width ?? 0, height ?? 0);
        return { body: file, meta: { }, url: uploadUrl, method: 'PUT' };
    }

    const getPresignedUrl = async (file, width, height) => {
        const apiKey = localStorage.getItem('apiKey');
        const response = await fetch("https://hermann-hambrecht-wedding.de/api/getuploadurl", {
            method: 'POST',
            headers: {
                'x-api-key': apiKey,
                'Content-Type':'application/json'
            },
            body: JSON.stringify({ name: file.name, contentType: file.type, width: width, height: height })
        });

        const data = await response.json();

        if(data.statusCode == 200) {
            const json = JSON.parse(data.body);
            return { uploadUrl: json.uploadUrl, fileUrl: file.name };
        }
        
        return { uploadUrl: null, fileUrl: null };
    };
    
    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status, allFiles) => { 
        console.log(status, meta, file) 

        function timeout(delay) {
            return new Promise( res => setTimeout(res, delay) );
        }

        if(status === 'done') {
            toast.success(`${file.name} wurde erfolgreich hochgeladen.`);
            allFiles.forEach(f => {
                if(f.file.name === file.name) {
                    f.remove();

                    if(allFiles.length == 0)
                        timeout(3000).then(() => getPhotoList());
                }
            })
        }
            
    }
    
    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }
    
    return (
        <Fragment>
            <Navbar />
            <section className={`wpo-portfolio-section-s2`} id="gallery">
            <div className="container">
                <div className="sortable-gallery">
                    <div className="gallery-filters"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <p style={{textAlign:'center'}}>Ihr könnt vor, während und nach der Hochzeit hier eure Bilder hochladen. Nach der Hochzeit werden wir euch über diese Seite auch die Bilder des Fotografs zur Verfügung stellen.</p>
                        <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            inputContent="Datei(en) ablegen oder klicken"
                            accept="image/*,audio/*,video/*"
                            SubmitButtonComponent={null}
                        />
                        </div>
                    </div>
                    
                    <div className="row" style={{marginTop:'20px'}}>
                        <div className="col-lg-12">
                        <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />

                        <Lightbox
                            slides={highQualityPhotos}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            // enable optional lightbox plugins
                            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Video]}
                        />
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default Gallery;