import React, { Fragment, useEffect } from 'react';

import Navbar from '../../components/Navbar/Navbar'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import 'react-dropzone-uploader/dist/styles.css'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Cookies from 'universal-cookie'

const Timeline = () => {

    useEffect(() => {
        const cookie = new Cookies();
        const code = cookie.get('code');

        if(code == undefined || code == null) {
            window.location = '/login';
        }
    })
    return (
        <Fragment>
            <Navbar />
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d66049.25845546646!2d9.166005975114574!3d49.43675594367942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47981da3fd6d89a5%3A0x58249ee370292cf6!2sLandgasthof%20Heidersbacher%20M%C3%BChle!5e0!3m2!1sde!2sde!4v1702667722384!5m2!1sde!2sde"></iframe>
                </div>
            </section>
            <section className={`wpo-portfolio-section-s2`} id="gallery">
            <div className="container">
                <div className="sortable-gallery">
                    <div className="gallery-filters"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <p style={{textAlign: 'center', marginTop:20}}>Wir halten euch auf dem Laufenden, der Zeitplan folgt Anfang 2024!</p> */}
                        <VerticalTimeline>
                            {/* <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="14:30 Uhr"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Freie Trauung</h3>
                                <p style={{color: '#fff'}}>
                                    Location Eventfabrik: Am Holm 82 - 23730 Neustadt in Holstein. Der Einlass ist ab 15:30 Uhr möglich. Ihr findet vor Ort ausreichend Parkmöglichkeiten.
                                </p>
                            </VerticalTimelineElement> */}
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="14:30 Uhr"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Freie Trauung</h3>
                                <p style={{color: '#fff'}}>
                                    Wir starten um 14:30 Uhr mit der freien Trauung. Anschließend freuen wir uns über eine großartige Party mit euch!
                                </p>
                            </VerticalTimelineElement>
                            {/* <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="16:30 Uhr"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Sektempfang</h3>
                                <p style={{color: '#fff'}}>
                                    Lasst uns die erste gemeinsame Zeit nutzen, zusammen anzustoßen und den Abend einzuläuten!
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="17:00 Uhr"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Bar Opening</h3>
                                <p style={{color: '#fff'}}>
                                    Um 17:00 Uhr öffnet die Bar! Ob Softdrinks, Bier, Wein oder Longdrinks - niemand soll bei uns verdursten!
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="17:15 Uhr"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Gruppenfotos</h3>
                                <p style={{color: '#fff'}}>
                                    Vermutlich kommen wir nie wieder in dieser Konstellation zusammen, also lasst uns den Moment festhalten! Der Fotograf kümmert sich um die Gruppenfotos.
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="17:45 Uhr"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Kids Dinner</h3>
                                <p style={{color: '#fff'}}>
                                    Für das Wohl unserer kleinen Gäste haben wir gesondert gesorgt. Sie starten vor den Erwachsenen, damit
                                    ihr anschließend selbst in Ruhe das Essen genießen könnt.
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="18:30 Uhr"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Kinder Kino</h3>
                                <p style={{color: '#fff'}}>

                                    Im Nebenraum zum Festsaal haben die Kleinen viele Möglichkeiten zum Spielen, Basteln und Malen. Für eine Betreuung ist gesorgt. Nach dem Kids Dinner gibt es zusätzlich ein Kinder Kino.
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="18:30 Uhr"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Barbecue</h3>
                                <p style={{color: '#fff'}}>
                                    Ab 18:30 Uhr beginnt das Barbecue. Neben Fleisch und Fisch wird es auch Vegetarisches geben. Allerlei Brote und Salate runden das Barbecue ab. Lasst es euch schmecken!
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="20:00 Uhr"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Hochzeitstor te</h3>
                                <p style={{color: '#fff'}}>
                                    Wir schneiden die Hochzeitstorte an! Für alle die keine Torte mögen, gibt es zusätzlich Dessert.
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="20:30 Uhr"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Hochzeitstanz</h3>
                                <p style={{color: '#fff'}}>
                                    Gegen 20:30 Uhr eröffnen wir die Tanzfläche mit unserem Hochzeitstanz. Anschließend seid ihr alle eingeladen das Tanzbein zu schwingen. 
                                </p>
                            </VerticalTimelineElement> */}
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#edae9a', color: '#701d23' }}
                                contentArrowStyle={{ borderRight: '7px solid  #edae9a' }}
                                date="Open End"
                                iconStyle={{ background: '#edae9a', color: '#fff' }}
                                
                            >
                                <h3 className="vertical-timeline-element-title" style={{color: '#701d23'}}>Gute Nacht!</h3>
                                <p style={{color: '#fff'}}>
                                    Gefeiert wird bis der Letzte nicht mehr kann!
                                </p>
                            </VerticalTimelineElement>
                            </VerticalTimeline>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
            
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default Timeline;